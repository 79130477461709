import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import Home from "@/views/Home.vue";
import List from "@/views/List.vue";
import General from "@/views/general/General.vue";
import Buyer from "@/views/general/Buyer.vue";
import Producer from "@/views/general/Producer.vue";
import Creditor from "@/views/general/Creditor.vue";
import CustomFeeder from "@/views/general/CustomFeeder.vue";
import Transporter from "@/views/general/Transporter.vue";
import Login from "@/views/Login.vue";
import Location from "@/views/Location.vue";
import IntakeBatch from "@/views/IntakeBatch.vue";
import ReprocessBatch from "@/views/ReprocessBatch.vue";
import DispatchToAbattoirBatch from "@/views/DispatchToAbattoirBatch.vue";
import DispatchToSiteBatch from "@/views/DispatchToSiteBatch.vue";
import Veterinary from "@/views/veterinary/Veterinary.vue";
import DispensaryItem from "@/views/veterinary/DispensaryItem.vue";
import AilmentCategory from "@/views/veterinary/AilmentCategory.vue";
import Ailment from "@/views/veterinary/Ailment.vue";
import Treatment from "@/views/veterinary/Treatment.vue";
import Vaccination from "@/views/veterinary/Vaccination.vue";
import Costs from "@/views/costs/Costs.vue";
import DispensaryItemCost from "@/views/costs/DispensaryItemCost.vue";
import MaterialCost from "@/views/costs/MaterialCost.vue";
import RationCost from "@/views/costs/RationCost.vue";
import Feed from "@/views/feed/Feed.vue";
import Ration from "@/views/feed/Ration.vue";
import Material from "@/views/feed/Material.vue";
import Mortality from "@/views/Mortality.vue";
import AnimalHistory from "@/views/AnimalHistory.vue";
import Hospital from "@/views/Hospital.vue";
import LostTags from "@/views/LostTags.vue";
import Kraal from "@/views/general/Kraal.vue";
import store from "@/store";
import * as changeCase from "change-case";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: ["/index.html", "/index"],
    name: "home",
    component: Home,
    meta: { requiresRegistration: true }
  },
  {
    path: "/list/:urlParam",
    name: "list",
    component: List,
    meta: { requiresRegistration: true },
    props: (route: Route) => {
      //if (!route.params.urlParam) throw Error("no urlParam for /list");
      let urlParam = route.params.urlParam === undefined ? undefined : decodeURI(route.params.urlParam as string);
      let setPath = route.query.setPath === undefined ? undefined : decodeURI(route.query.setPath as string);
      let continueRoute = route.query.continueRoute === undefined ? undefined : decodeURI(route.query.continueRoute as string);
      let backRouteOverride = route.query.backRouteOverride === undefined ? undefined : decodeURI(route.query.backRouteOverride as string);
      return {
        urlParam: urlParam,
        setPath: setPath,
        continueRoute: continueRoute,
        backRouteOverride: backRouteOverride
      };
    }
    //props: true
  },
  {
    path: "/intake-batch",
    name: "intake-batch",
    component: IntakeBatch,
    meta: { requiresRegistration: true }
  },
  {
    path: "/reprocess-batch",
    name: "reprocess-batch",
    component: ReprocessBatch,
    meta: { requiresRegistration: true }
  },
  {
    path: "/dispatch-to-abattoir-batch",
    name: "dispatch-to-abattoir-batch",
    component: DispatchToAbattoirBatch,
    meta: { requiresRegistration: true }
  },
  {
    path: "/dispatch-to-site-batch",
    name: "dispatch-to-site-batch",
    component: DispatchToSiteBatch,
    meta: { requiresRegistration: true }
  },
  {
    path: "/general",
    name: "general",
    component: General,
    meta: { requiresRegistration: true }
  },
  {
    path: "/buyer",
    name: "buyer",
    component: Buyer,
    meta: { requiresRegistration: true }
  },
  {
    path: "/producer",
    name: "producer",
    component: Producer,
    meta: { requiresRegistration: true }
  },
  {
    path: "/creditor",
    name: "creditor",
    component: Creditor,
    meta: { requiresRegistration: true }
  },
  {
    path: "/custom-feeder",
    name: "custom-feeder",
    component: CustomFeeder,
    meta: { requiresRegistration: true }
  },
  {
    path: "/transporter",
    name: "transporter",
    component: Transporter,
    meta: { requiresRegistration: true }
  },
  {
    path: "/veterinary",
    name: "veterinary",
    component: Veterinary,
    meta: { requiresRegistration: true }
  },
  {
    path: "/dispensary-item",
    name: "dispensary-item",
    component: DispensaryItem,
    meta: { requiresRegistration: true }
  },
  {
    path: "/ailment-category",
    name: "ailment-category",
    component: AilmentCategory,
    meta: { requiresRegistration: true }
  },
  {
    path: "/ailment",
    name: "ailment",
    component: Ailment,
    meta: { requiresRegistration: true }
  },
  {
    path: "/treatment",
    name: "treatment",
    component: Treatment,
    meta: { requiresRegistration: true }
  },
  {
    path: "/vaccination",
    name: "vaccination",
    component: Vaccination,
    meta: { requiresRegistration: true }
  },
  {
    path: "/mortality",
    name: "mortality",
    component: Mortality,
    meta: { requiresRegistration: true }
  },
  {
    path: "/costs",
    name: "costs",
    component: Costs,
    meta: { requiresRegistration: true }
  },
  {
    path: "/dispensary-item-cost",
    name: "dispensary-item-cost",
    component: DispensaryItemCost,
    meta: { requiresRegistration: true }
  },
  {
    path: "/material-cost",
    name: "material-cost",
    component: MaterialCost,
    meta: { requiresRegistration: true }
  },
  {
    path: "/ration-cost",
    name: "ration-cost",
    component: RationCost,
    meta: { requiresRegistration: true }
  },
  {
    path: "/feed",
    name: "feed",
    component: Feed,
    meta: { requiresRegistration: true }
  },
  {
    path: "/ration",
    name: "ration",
    component: Ration,
    meta: { requiresRegistration: true }
  },
  {
    path: "/material",
    name: "material",
    component: Material,
    meta: { requiresRegistration: true }
  },
  {
    path: "/animal-history",
    name: "animal-history",
    component: AnimalHistory,
    meta: { requiresRegistration: true }
  },
  // {
  //   path: "/download",
  //   name: "download",
  //   component: Download,
  //   meta: { requiresRegistration: true }
  // },
  {
    path: "/hospital",
    name: "hospital",
    component: Hospital,
    meta: { requiresRegistration: true }
  },
  {
    path: "/lost-tags",
    name: "lost-tags",
    component: LostTags,
    meta: { requiresRegistration: true }
  },
  {
    path: "/kraal",
    name: "kraal",
    component: Kraal,
    meta: { requiresRegistration: true }
  },
  {
    path: "/location",
    name: "location",
    component: Location
    // beforeEnter: (to: Route, from: Route, next: any) => {
    //   if (store.getters["user/isUserRegistered"]()) next();
    //   else next("/login");
    // }
  },
  {
    path: "/login",
    name: "login",
    component: Login
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to: Route, from: Route, next: any) => {
  ////////
  //
  let appBarText = "";
  switch (to.name) {
    case "home":
      appBarText = "Main Menu"; break;
    case "list":
      const url = to.params.urlParam;
      switch (url) {
        case "custom-feeders":
          appBarText = "Owners / Custom Feeders"; break;
        default: appBarText = changeCase.capitalCase(url); break;
      }
      break;
    case "custom-feeder":
      appBarText = "Owner / Custom Feeder"; break;
    default:
      appBarText = to.name ? changeCase.capitalCase(to.name) : "";
  }
  store.commit("appBarText", appBarText);

  //
  ////////
  //
  let requiresRegistration: boolean = to.matched.some(record => record.meta.requiresRegistration);
  let isUserRegistered: boolean = store.getters["user/isUserRegistered"]();

  if (requiresRegistration && !isUserRegistered) {
    next("login");
  } else if (to.name !== "location" && to.name !== "login" && !(store as any).state.user.location) {
    next("location");
  } else {
    next();
  }
  //
  ////////
});

export default router;
