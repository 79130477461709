import store from "@/store";
import * as Models from "@gigalot/data-models";

export async function listAilment(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listAilment($guid: String!, $onlyActive: Boolean!) {
        listAilment(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          ailment
          category
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listAilment;
}

export async function listAilmentCategory(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listAilmentCategory($guid: String!, $onlyActive: Boolean!) {
        listAilmentCategory(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          category
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listAilmentCategory;
}

export async function listAnimal(search: string = "") {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listAnimal($guid: String!, $search: String) {
        listAnimal(guid: $guid, search: $search) {
          typename
          guid
          sgtin
          tagId
          owner
          breed
          gender
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        search: search
      }
    },
    { root: true }
  );
  // console.log("graphQL:");
  // console.dir(json);
  console.log(`listAnimal graphQL returning ${json.data.listAnimal.length} items.`)
  return json.data.listAnimal;

  // let url = `https://pi.gigalot.systems:7766/listAnimalItems`;
  // let body = JSON.stringify({ guid: (store.state as any).user.location.guid });
  // console.log("body: " + body);
  // let res = await fetch(url, {
  //   method: "POST",
  //   headers: new Headers({
  //     "Content-Type": "application/json"
  //   }),
  //   body: body
  // });
  // if (!res.ok) throw Error("listAnimalItems response not OK : " + (await res.text()));
  // let listAnimalItems = await res.json();
  // return listAnimalItems;
}

export async function listBuyer(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listBuyer($guid: String!, $onlyActive: Boolean!) {
        listBuyer(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          name
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listBuyer;
}

export async function listCostDispensaryItem(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listCostDispensaryItem($guid: String!, $onlyActive: Boolean!) {
        listCostDispensaryItem(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          code
          description
          latestCostValue
          latestCostPer
          latestCostUnit
          latestCostSince
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listCostDispensaryItem;
}

export async function listCostMaterial(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listCostMaterial($guid: String!, $onlyActive: Boolean!) {
        listCostMaterial(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          name
          description
          latestCostString
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listCostMaterial;
}

export async function listCostRation(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listCostRation($guid: String!, $onlyActive: Boolean!) {
        listCostRation(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          name
          description
          latestCostString
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listCostRation;
}

export async function listCreditor(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listCreditor($guid: String!, $onlyActive: Boolean!) {
        listCreditor(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          name
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listCreditor;
}

export async function listCustomFeeder(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listCustomFeeder($guid: String!, $onlyActive: Boolean!) {
        listCustomFeeder(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          name
          companyName
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listCustomFeeder;
}

export async function listDispatchToAbattoirBatch(includeSettled: boolean, pgOptions: Models.PaginationOptions) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listDispatchToAbattoirBatch($guid: String!, $includeSettled: Boolean!, $pgOptions: PaginationOptionsInput!) {
        listDispatchToAbattoirBatch(guid: $guid, includeSettled: $includeSettled, pgOptions: $pgOptions) {
          total
          items {
            typename
            guid
            dispatchType
            reference
            ownerName
            quantityExpected
            description
            settled
            time
            abattoir
            quantityDispatched
            totalDispatchMass
            averageDispatchMass
            notes
          }
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        includeSettled: includeSettled,
        pgOptions
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listDispatchToAbattoirBatch;
}

export async function listDispatchToSiteBatch(includeSettled: boolean, pgOptions: Models.PaginationOptions) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listDispatchToSiteBatch($guid: String!, $includeSettled: Boolean!, $pgOptions: PaginationOptionsInput!) {
        listDispatchToSiteBatch(guid: $guid, includeSettled: $includeSettled, pgOptions: $pgOptions) {
          total
          items {
            typename
            guid
            dispatchType
            reference
            ownerName
            quantityExpected
            description
            settled
            time
            site
            quantityDispatched
            totalDispatchMass
            averageDispatchMass
            notes
          }
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        includeSettled: includeSettled,
        pgOptions
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listDispatchToSiteBatch;
}

export async function listDispensaryItem(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listDispensaryItem($guid: String!, $onlyActive: Boolean!) {
        listDispensaryItem(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          description
          unit
          withdrawal
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listDispensaryItem;
}

export async function listIntakeBatch(includeSettled: boolean, pgOptions: Models.PaginationOptions) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listIntakeBatch($guid: String!, $includeSettled: Boolean!, $pgOptions: PaginationOptionsInput!) {
        listIntakeBatch(guid: $guid, includeSettled: $includeSettled, pgOptions: $pgOptions) {
          total
          items {
            typename
            guid
            reference
            ownerName
            quantityExpected
            quantityDead
            description
            settled
            timeCreated
            quantityProcessed
          }
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        includeSettled,
        pgOptions
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listIntakeBatch;
}

export async function listLostTags() {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listLostTags($guid: String!) {
        listLostTags(guid: $guid) {
          typename
          guid
          notes
          reference
          refType
          time
          tags
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listLostTags;
}

export async function listMortality(pgOptions: Models.PaginationOptions) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listMortality($guid: String!, $pgOptions: PaginationOptionsInput!) {
        listMortality(guid: $guid, pgOptions: $pgOptions) {
          total
          items {
            typename
            guid
            time
            tagId
            ailments
            owner
            breed
            gender
          }
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        pgOptions
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listMortality;
}

export async function listOfficeHospitalResult(pgOptions: Models.PaginationOptions) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listOfficeHospitalResult($guid: String!, $pgOptions: PaginationOptionsInput!) {
        listOfficeHospitalResult(guid: $guid, pgOptions: $pgOptions) {
          total
          items {
            typename
            guid
            time
            tagId
            ailments
            owner
            breed
            gender
          }
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        pgOptions
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listOfficeHospitalResult;
}

export async function listProcessingResult(processingFunction?: Models.ProcessingFunction, fromDate?: number, toDate?: number) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listProcessingResult($guid: String!, $processingFunction: String, $fromDate: Float, $toDate: Float) {
        listProcessingResult(guid: $guid, processingFunction: $processingFunction, fromDate: $fromDate, toDate: $toDate) {
          typename
          guid
          reference
          time
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        processingFunction: processingFunction,
        fromDate: fromDate,
        toDate: toDate
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listProcessingResult;
}

export async function listProducer(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listProducer($guid: String!, $onlyActive: Boolean!) {
        listProducer(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          name
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listProducer;
}

export async function listReprocessBatch(includeSettled: boolean, pgOptions: Models.PaginationOptions) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listReprocessBatch($guid: String!, $includeSettled: Boolean!, $pgOptions: PaginationOptionsInput!) {
        listReprocessBatch(guid: $guid, includeSettled: $includeSettled, pgOptions: $pgOptions) {
          total
          items {
            typename
            guid
            reference
            type
            owner
            newOwner
            settled
            description
            quantityExpected
            timeCreated
          }
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        includeSettled: includeSettled,
        pgOptions
      },
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listReprocessBatch;
}

export async function listScanningResult(scanningFunction?: Models.ScanningFunction, fromDate?: number, toDate?: number) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listScanningResult($guid: String!, $scanningFunction: String, $fromDate: Float, $toDate: Float) {
        listScanningResult(guid: $guid, scanningFunction: $scanningFunction, fromDate: $fromDate, toDate: $toDate) {
          typename
          guid
          reference
          time
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        scanningFunction: scanningFunction,
        fromDate: fromDate,
        toDate: toDate
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listScanningResult;
}

export async function listTransporter(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listTransporter($guid: String!, $onlyActive: Boolean!) {
            listTransporter(guid: $guid, onlyActive: $onlyActive) {
              typename
              guid
              name
              active
            }
          }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listTransporter;
}

export async function listTreatment(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listTreatment($guid: String!, $onlyActive: Boolean!) {
        listTreatment(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          description
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listTreatment;
}

export async function listVaccination(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listVaccination($guid: String!, $onlyActive: Boolean!) {
        listVaccination(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          description
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listVaccination;
}

export async function listKraal(onlyActive: boolean) {
  const json = await store.dispatch(
    "graphQl",
    {
      gql: `query listKraal($guid: String!, $onlyActive: Boolean!) {
        listKraal(guid: $guid, onlyActive: $onlyActive) {
          typename
          guid
          kraalId
          kraalType
          recommendedCapacity
          description
          active
        }
      }`,
      variables: {
        guid: (store as any).state.user.location.guid,
        onlyActive: onlyActive
      }
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.listKraal;
}
