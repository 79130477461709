
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["kraal.kraalId", "kraal.kraalType", "kraal.recommendedCapacity", "kraal.description", "kraal.active"]),
  },
})
export default class Producer extends Vue {
  changed: boolean = false;
  kraalIdAlreadySet: boolean = false;

  types = ["Background", "Feedlot", "Hospital", "Precon", "Yard", "Holding"];

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  get kraal(): Models.Kraal {
    return this.$store.getters["storage"]().kraal;
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "kraal", value: {} });
        this.$router.push({ path: "list/kraals" });
      },
    });

    this.kraalIdAlreadySet = this.$store.getters["storage"]().kraal?.kraalId?.length > 0;
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "kraal", value: {} });
        this.$router.push({ path: "list/kraals" });
      },
    });
  }

  save() {
    if (!this.kraal.kraalId) {
      this.$store.commit("popup/displayOk", `Can not save, kraal ID required.`);
      return;
    }

    if (!this.kraal.kraalType) {
      this.$store.commit("popup/displayOk", `Can not save, kraal type required.`);
      return;
    }

    const rc = this.kraal.recommendedCapacity;
    if (rc === undefined || isNaN(rc) || Math.floor(rc) !== rc) {
      this.$store.commit("popup/displayOk", `Can not save, recommended capacity must be a valid number.`);
      return;
    }

    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          //TODO: popup and block saving if another producer with the same name (different guid) is found
          //TODO: popup if multiple producer companies share the same name
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().kraal);
          let kraalClone = lodash.cloneDeep(this.$store.getters["storage"]().kraal);
          kraalClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation setKraal($guid: String!, $kraal: KraalInput!) {
                setKraal(guid: $guid, kraal: $kraal) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, kraal: kraalClone as Models.Kraal },
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let kraal = this.$store.getters["storage"]().kraal as Models.Kraal;
          kraal.changeTracking = json.data.setKraal;

          this.$router.push({ path: "list/kraals" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  mounted() {
    if (!["paul@gigalot.co.za", "dolan@gigalot.co.za", "dawie@gigalot.co.za"].includes(this.$store.state.user.user?.email)) {
      this.$router.push({ path: "/" });
    }
  }

  destroyed() {}
}
