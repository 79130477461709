
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
//import { factory } from "@gigalot/data-models";
import Header from "@/data-table-header";
import { getItemFromServer } from "@/helpers/get-item-from-server";
import * as Models from "@gigalot/data-models";
import {
  listAilment,
  listAilmentCategory,
  listBuyer,
  listCostDispensaryItem,
  listCreditor,
  listCustomFeeder,
  listDispatchToAbattoirBatch,
  listDispatchToSiteBatch,
  listDispensaryItem,
  listIntakeBatch,
  listKraal,
  listLostTags,
  listMortality,
  listOfficeHospitalResult,
  listProducer,
  listReprocessBatch,
  listTransporter,
  listTreatment,
  listVaccination,
} from "@/helpers/graphql-list-items";
import { visualNumber } from "@/helpers/visual-tag";

@Component({
  components: {
    DimssaButton,
  },
})
export default class List extends Vue {
  //these props are set as url queries (remember to url encode)
  @Prop() urlParam!: string;
  @Prop() setPath!: string; //path to set selected item to
  @Prop() continueRoute!: string; //url path to go to when continue is pressed
  @Prop() backRouteOverride!: string;

  get isPaginated() {
    return ["DispatchToAbattoirBatch", "DispatchToSiteBatch", "IntakeBatch", "Mortality", "OfficeHospitalResult", "ReprocessBatch"].includes(
      this.$store.state.list.typename
    );
  }

  loading: boolean = true;

  debouncedFetchData = lodash.debounce(this.fetchData, 500);

  totalItems = 15;

  options: any = {};

  moment = this.$store.state.moment;

  get paginationOptions() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;

    return {
      page,
      itemsPerPage,
      sortBy,
      sortDesc,
      search: this.search,
      timeFrom: this.moment(this.fromDateString, "YYYY-MM-DD").valueOf(),
      timeUntil: this.moment(this.toDateString, "YYYY-MM-DD").valueOf(),
    };
  }

  fromDateMenu: boolean = false;
  toDateMenu: boolean = false;

  fromDateString: string = this.moment().subtract(2, "months").format("YYYY-MM-DD");
  toDateString: string = this.moment().format("YYYY-MM-DD");

  //dataFetchStarted: boolean = false;

  async fetchData() {
    this.loading = true;
    //this.dataFetchStarted = true;

    await this.onRefresh();

    this.loading = false;
  }

  includeSettledBatches: boolean = false;

  @Watch("urlParam")
  async onUrlParamChange(val: any) {
    this.initList();
    //this.onRefresh();
  }

  async includeSettledBatchesChange(includeSettledBatches: boolean) {
    this.onRefresh();
  }

  includeInactive: boolean = false;
  includeInactiveChange(includeInactive: any) {
    this.onRefresh();
  }

  onBack() {
    if (this.backRouteOverride !== undefined) this.$router.push({ name: this.backRouteOverride });
    else this.$router.push({ name: this.list.onBack.routeName });
  }

  refreshButtonState: ButtonState = "ready";
  refreshErrorMessage: string = "";
  async onRefresh() {
    try {
      this.refreshErrorMessage = "";
      this.refreshButtonState = "busy";
      await this.refreshList();
      this.refreshButtonState = "success";
    } catch (err) {
      console.error("onRefresh() error:");
      console.error(err);
      this.refreshErrorMessage = (err as any)?.toString();
      this.refreshButtonState = "error";
    }
  }

  get getEditButtonState() {
    if (["dispensary-item-costs", "ration-costs", "material-costs"].includes(this.urlParam) && this.selected.length === 0) return "disabled";
    else if (this.selected.length === 0) return "ready";
    else return this.editButtonState;
  }

  editButtonState: ButtonState = "ready";
  editErrorMessage: string = "";
  async onEdit() {
    const list = this.$store.state.list;
    let item;

    /*
      The CostX (i.e CostDispensaryItem) items work a bit differently
      They are attached to an X (i.e DispensaryItem), using the same guid
      The server will create them, so we don't need to use the factory() method
      We will get X and CostX and save it to local storage
    */
    //TODO: make this work with other Cost items
    if (list.typename === "CostDispensaryItem") {
      try {
        this.editButtonState = "busy";
        const dispensaryItem = await getItemFromServer("DispensaryItem", this.selected[0].guid);
        const costDispensaryItem = await getItemFromServer("CostDispensaryItem", this.selected[0].guid);
        this.editButtonState = "success";

        this.$store.commit("updateUserLocationField", { path: "dispensaryItem", value: dispensaryItem });
        this.$store.commit("updateUserLocationField", { path: "costDispensaryItem", value: costDispensaryItem });
        this.$router.push({ name: list.onEdit.routeName });
      } catch (err) {
        console.error("onEdit() error:");
        console.error(err);
        this.editErrorMessage = (err as any)?.toString() ?? "";
        this.editButtonState = "error";
      }
      return;
    }

    if (this.selected.length && this.selected[0]) {
      try {
        this.editButtonState = "busy";
        item = await getItemFromServer(list.typename, this.selected[0].guid);
        this.editButtonState = "success";
      } catch (err) {
        console.error("onEdit() error:");
        console.error(err);
        this.editErrorMessage = (err as any)?.toString() ?? "";
        this.editButtonState = "error";
      }
    } else {
      item = this.factory(list.typename);
    }

    if (item) {
      this.$store.commit("updateUserLocationField", { path: this.list.onEdit.listUponEditPath, value: item });
      this.$router.push({ name: list.onEdit.routeName });
    }
  }

  factory(typename: string) {
    const item = Models.factory(typename);

    //TODO: consider moving this data-models-gql
    if (item.typename === "IntakeBatch") {
      if (!item.transport.length) {
        item.transport[0] = new Models.Transport();
        item.transport[0].weighbridges.push({ location: "supplier", reference: "", mass: undefined });
        item.transport[0].weighbridges.push({ location: "arrival", reference: "", mass: undefined });

        //item.transport[0].score.push({ type: "group", score: "" });
        item.transport[0].score.push({ type: "condition", score: "" });
        item.transport[0].score.push({ type: "genetics", score: "" });
        item.transport[0].score.push({ type: "general-overview", score: "" });
      }

      if (!item.transport[0].origin) {
        item.transport[0].origin = new Models.Origin();
        item.transport[0].origin.country = "";
        item.transport[0].origin.province = "";
        item.transport[0].origin.town = "";
        item.transport[0].origin.code = "";
        item.transport[0].origin.gps = { lat: 0, lon: 0 };
        //    item.transport[0].origin.description = "";
      }
    }

    return item;
  }

  get list() {
    return this.$store.state.list;
  }

  //TODO: can we remove this? It doesn't look like this is being used.
  onContinue() {
    //hide if set path is undefined
    //disable if nothing is selected
    //use set path and setDescendantProp (rather use updateUserLocationField(state, { path, value })). path can be given, and value can be selected[0]
    //  idea here is that we might have come to a selection list to select something needed for another model, for example an intake batch needs to have a creditor selected
    //updateUserLocationField(state, { path, value })
    this.$store.commit("updateUserLocationField", { path: this.list.onContinue.listUponSelectionSetPath, value: lodash.cloneDeep(this.selected[0]) });
    //this.$router.go(-1);
    this.$router.push({ name: this.list.onContinue.routeName });
  }

  search: string = "";
  selected: any[] = [];

  @Watch("search")
  onSearchChanged() {
    //Reset to page 1 when search is modified.
    this.options.page = 1;
  }

  destroyed() {
    // Clear list when we leave, otherwise error occurs because server is given a bad sortBy field.
    this.$store.commit("list/list", {
      title: "",
      headers: [],
      items: [],
      typename: "",
      //onEdit: { routeName: "producer", listUponEditPath: "producer" }
    });
  }

  async created() {
    this.$store.commit("navFuncs", { save: undefined, back: this.onBack });
    this.$store.commit("list/clearList");
    this.selected = [];

    await this.initList();
    //await this.onRefresh(true); //skip = true, initList triggers some items to refresh, those can be skipped.

    if (this.setPath !== undefined && this.continueRoute !== undefined) {
      this.$store.commit("list/onContinue", {
        routeName: this.continueRoute,
        listUponSelectionSetPath: this.setPath,
      });
    } else {
      this.$store.commit("list/onContinue", undefined);
    }
  }

  async refreshList() {
    console.log(`refreshList()`);
    //if (this.isPaginated && !this.dataFetchStarted) return;
    this.loading = true;
    switch (this.urlParam) {
      case "buyers":
        await this.refreshBuyers();
        break;
      case "producers":
        await this.refreshProducers();
        break;
      case "creditors":
        await this.refreshCreditors();
        break;
      case "custom-feeders":
        await this.refreshCustomFeeders();
        break;
      case "transporters":
        await this.refreshTransporters();
        break;
      case "intake-batches":
        await this.refreshIntakeBatches();
        break;
      case "reprocess-batches":
        await this.refreshReprocessBatches();
        break;
      case "dispatch-to-abattoir-batches":
        await this.refreshDispatchToAbattoirBatches();
        break;
      case "dispatch-to-site-batches":
        await this.refreshDispatchToSiteBatches();
        break;
      case "dispensary-items":
        await this.refreshDispensaryItems();
        break;
      case "treatments":
        await this.refreshTreatments();
        break;
      case "vaccinations":
        await this.refreshVaccinations();
        break;
      case "ailment-categories":
        await this.refreshAilmentCategories();
        break;
      case "ailments":
        await this.refreshAilments();
        break;
      case "dispensary-item-costs":
        await this.refreshDispensaryItemCosts();
        break;
      case "ration-costs":
        await this.rationCosts();
        break;
      case "material-costs":
        await this.materialCosts();
        break;
      case "mortalities":
        await this.refreshMortalities();
        break;
      case "office-hospital-results":
        await this.refreshOfficeHospitalResults();
        break;
      case "lost-tags":
        await this.refreshLostTags();
        break;
      case "kraals":
        await this.refreshKraals();
        break;
      default:
        this.$store.commit("list/list", {
          title: "",
          headers: [],
          items: [],
          typename: "",
          //onEdit: { routeName: "producer", listUponEditPath: "producer" }
        });
        throw Error(`TODO: add ${this.urlParam} (this.urlParam) to List.vue's logic.`);
    }
    this.loading = false;
  }

  async initList() {
    console.log("initList()");
    switch (this.urlParam) {
      case "buyers":
        await this.initBuyers();
        await this.onRefresh();
        break;
      case "producers":
        await this.initProducers();
        await this.onRefresh();
        break;
      case "creditors":
        await this.initCreditors();
        await this.onRefresh();
        break;
      case "custom-feeders":
        await this.initCustomFeeders();
        await this.onRefresh();
        break;
      case "transporters":
        await this.initTransporters();
        await this.onRefresh();
        break;
      case "intake-batches":
        await this.initIntakeBatches(); //server side pagination, skip onRefresh()
        break;
      case "reprocess-batches":
        await this.initReprocessBatches(); //server side pagination, skip onRefresh()
        break;
      case "dispatch-to-abattoir-batches":
        await this.initDispatchToAbattoirBatches(); //server side pagination, skip onRefresh()
        break;
      case "dispatch-to-site-batches":
        await this.initDispatchToSiteBatches(); //server side pagination, skip onRefresh()
        break;
      case "dispensary-items":
        await this.initDispensaryItems();
        await this.onRefresh();
        break;
      case "treatments":
        await this.initTreatments();
        await this.onRefresh();
        break;
      case "vaccinations":
        await this.initVaccinations();
        await this.onRefresh();
        break;
      case "ailment-categories":
        await this.initAilmentCategories();
        await this.onRefresh();
        break;
      case "ailments":
        await this.initAilments();
        await this.onRefresh();
        break;
      case "dispensary-item-costs":
        await this.initDispensaryItemCosts();
        await this.onRefresh();
        break;
      case "ration-costs":
        await this.rationCosts();
        break;
      case "material-costs":
        await this.materialCosts();
        break;
      case "mortalities":
        await this.initMortalities(); //server side pagination, skip onRefresh()
        break;
      case "office-hospital-results":
        await this.initOfficeHospitalResults(); //server side pagination, skip onRefresh()
        break;
      case "lost-tags":
        await this.initLostTags();
        await this.onRefresh();
        break;
      case "kraals":
        await this.initKraals();
        await this.onRefresh();
        break;
      default:
        this.$store.commit("list/list", {
          title: "",
          headers: [],
          items: [],
          typename: "",
          //onEdit: { routeName: "producer", listUponEditPath: "producer" }
        });
        throw Error(`TODO: add ${this.urlParam} (this.urlParam) to List.vue's logic.`);
    }
  }

  async refreshBuyers() {
    let items = await listBuyer(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshProducers() {
    let items = await listProducer(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshCreditors() {
    let items = await listCreditor(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshCustomFeeders() {
    let items = await listCustomFeeder(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshTransporters() {
    let items = await listTransporter(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshIntakeBatches() {
    // if (this.paginationOptions.itemsPerPage === undefined) {
    //   console.log("SKIP"); //Weird bug where this gets before and after pgOptions set
    //   return;
    // }
    const t = await listIntakeBatch(this.includeSettledBatches, this.paginationOptions);
    this.totalItems = t.total;
    this.$store.commit("list/items", t.items);
  }

  async refreshReprocessBatches() {
    // if (this.paginationOptions.itemsPerPage === undefined) {
    //   console.log("SKIP"); //Weird bug where this gets before and after pgOptions set
    //   return;
    // }
    const t = await listReprocessBatch(this.includeSettledBatches, this.paginationOptions);
    this.totalItems = t.total;
    this.$store.commit("list/items", t.items);
  }

  async refreshDispatchToAbattoirBatches() {
    // if (this.paginationOptions.itemsPerPage === undefined) {
    //   console.log("SKIP"); //Weird bug where this gets before and after pgOptions set
    //   return;
    // }
    let t = await listDispatchToAbattoirBatch(this.includeSettledBatches, this.paginationOptions);
    this.totalItems = t.total;
    this.$store.commit("list/items", t.items);
  }

  async refreshDispatchToSiteBatches() {
    // if (this.paginationOptions.itemsPerPage === undefined) {
    //   console.log("SKIP"); //Weird bug where this gets before and after pgOptions set
    //   return;
    // }
    let t = await listDispatchToSiteBatch(this.includeSettledBatches, this.paginationOptions);
    this.totalItems = t.total;
    this.$store.commit("list/items", t.items);
  }

  async refreshDispensaryItems() {
    let items = await listDispensaryItem(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshTreatments() {
    let items = await listTreatment(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshVaccinations() {
    let items = await listVaccination(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshAilmentCategories() {
    let items = await listAilmentCategory(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshAilments() {
    let items = await listAilment(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async refreshDispensaryItemCosts() {
    let items = await listCostDispensaryItem(!this.includeInactive);
    items = items.map((i: any) => {
      const date = this.moment(i.latestCostSince).format("YYYY-MM-DD");
      let latestCostString = "";
      if (![i.latestCostValue, i.latestCostPer, i.latestCostUnit, i.latestCostSince].includes(null))
        latestCostString = `R ${i.latestCostValue} per ${i.latestCostPer} ${i.latestCostUnit === "each" ? "" : i.latestCostUnit} since ${date}`;
      else latestCostString = "N/A";
      return {
        ...i,
        latestCostString,
      };
    });
    this.$store.commit("list/items", items);
  }

  async refreshMortalities() {
    // if (this.paginationOptions.itemsPerPage === undefined) {
    //   console.log("SKIP"); //Weird bug where this gets before and after pgOptions set
    //   return;
    // }
    const t = await listMortality(this.paginationOptions);
    t.items = t.items.map((i: any) => ({ ...i, humanTime: i.time !== undefined ? this.moment(i.time).format("YYYY-MM-DD") : "N/A" }));
    this.totalItems = t.total;
    this.$store.commit("list/items", t.items);
  }

  async refreshOfficeHospitalResults() {
    // if (this.paginationOptions.itemsPerPage === undefined) {
    //   console.log("SKIP"); //Weird bug where this gets before and after pgOptions set
    //   return;
    // }
    const t = await listOfficeHospitalResult(this.paginationOptions);
    t.items = t.items.map((i: any) => ({ ...i, humanTime: i.time !== undefined ? this.moment(i.time).format("YYYY-MM-DD") : "N/A" }));
    this.totalItems = t.total;
    this.$store.commit("list/items", t.items);
  }

  async refreshLostTags() {
    let items = await listLostTags();
    items = items.map((i: any) => {
      //return ({ ...i, tagsUi: i.tags.map((t: string) => visualNumber(t)).join(", ") }
      const tagsUi = i.tags.map((t: string) => visualNumber(t).replace(/^0+/, "")).join(", ");

      let refTypeUi;
      if (i.refType === "none") refTypeUi = "None";
      if (i.refType === "new") refTypeUi = "New Intake";
      if (i.refType === "roll-call") refTypeUi = "Count";
      if (i.refType === "dispatch-to-abattoir-gantry") refTypeUi = "Dispatch (Group)";
      if (i.refType === "dispatch-to-abattoir-processed") refTypeUi = "Dispatch (Single)";
      if (i.refType === "group-weigh") refTypeUi = "Group Weigh";
      if (i.refType === "reimplant") refTypeUi = "Re-implant";
      if (i.refType === "reprocess") refTypeUi = "Re-process";
      if (i.refType === "reweigh") refTypeUi = "Re-weigh";

      return { ...i, tagsUi, refTypeUi, timeUi: this.moment(i.time).format("YYYY-MM-DD HH:mm") };
    });
    this.$store.commit("list/items", items);
  }

  async refreshKraals() {
    let items = await listKraal(!this.includeInactive);
    this.$store.commit("list/items", items);
  }

  async initBuyers() {
    let headers = [new Header({ text: "Name", value: "name" })];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Buyers",
      headers: headers,
      items: [],
      typename: "Buyer",
      onEdit: { routeName: "buyer", listUponEditPath: "buyer" },
      onBack: { routeName: "general" },
      sortBy: "name",
      sortDesc: false,
    });
  }

  async initProducers() {
    let headers = [new Header({ text: "Name", value: "name" })];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Producers",
      headers: headers,
      items: [],
      typename: "Producer",
      onEdit: { routeName: "producer", listUponEditPath: "producer" },
      onBack: { routeName: "general" },
      sortBy: "name",
      sortDesc: false,
    });
  }

  async initCreditors() {
    let headers = [new Header({ text: "Name", value: "name" })];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Creditors",
      headers: headers,
      items: [],
      typename: "Creditor",
      onEdit: { routeName: "creditor", listUponEditPath: "creditor" },
      onBack: { routeName: "general" },
      sortBy: "name",
      sortDesc: false,
    });
  }

  async initCustomFeeders() {
    let headers = [new Header({ text: "Name", value: "name" }), new Header({ text: "Company Name", value: "companyName" })];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Owners / Custom Feeders",
      headers: headers,
      items: [],
      typename: "CustomFeeder",
      onEdit: { routeName: "custom-feeder", listUponEditPath: "customFeeder" },
      onBack: { routeName: "general" },
      sortBy: "name",
      sortDesc: false,
    });
  }

  async initTransporters() {
    let headers = [new Header({ text: "Company Name", value: "name" })];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Transporters",
      headers: headers,
      items: [],
      typename: "Transporter",
      onEdit: { routeName: "transporter", listUponEditPath: "transporter" },
      onBack: { routeName: "general" },
      sortBy: "name",
      sortDesc: false,
    });
  }

  async initIntakeBatches() {
    let headers = [];
    headers.push(new Header({ text: "Setup Date", value: "timeCreated", divider: true, sortable: true }));
    headers.push(new Header({ text: "Reference", value: "reference", divider: true, sortable: true }));
    headers.push(new Header({ text: "Owner", value: "ownerName", divider: true, sortable: false }));
    headers.push(new Header({ text: "Qty Expected", value: "quantityExpected", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Qty Processed", value: "quantityProcessed", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Qty Dead", value: "quantityDead", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Description", value: "description", sortable: false }));
    if (this.includeSettledBatches) headers.push(new Header({ text: "Settled", value: "settled", divider: true, sortable: false }));
    this.$store.commit("list/list", {
      title: "Intake Batches",
      headers: headers,
      items: [],
      typename: "IntakeBatch",
      onEdit: { routeName: "intake-batch", listUponEditPath: "intakeBatch" },
      onBack: { routeName: "home" },
      sortBy: "timeCreated",
      sortDesc: true,
    });
  }

  async initReprocessBatches() {
    let headers = [];
    headers.push(new Header({ text: "Setup Date", value: "timeCreated", divider: true, sortable: true }));
    headers.push(new Header({ text: "Reference", value: "reference", divider: true, sortable: true }));
    headers.push(new Header({ text: "Type", value: "type", divider: true, sortable: false }));
    headers.push(new Header({ text: "Owner", value: "owner", divider: true, sortable: false }));
    headers.push(new Header({ text: "New Owner", value: "newOwner", divider: true, sortable: false }));
    if (this.includeSettledBatches) headers.push(new Header({ text: "Settled", value: "settled", divider: true, sortable: false }));

    this.$store.commit("list/list", {
      title: "Re-Work Batches",
      headers: headers,
      items: [],
      typename: "ReprocessBatch",
      onEdit: { routeName: "reprocess-batch", listUponEditPath: "reprocessBatch" },
      onBack: { routeName: "home" },
      sortBy: "timeCreated",
      sortDesc: true,
    });
  }

  async initDispatchToAbattoirBatches() {
    let headers = [];
    headers.push(new Header({ text: "Type", value: "dispatchType", divider: true, sortable: false }));
    headers.push(new Header({ text: "Dispatch Date", value: "time", divider: true, sortable: false }));
    headers.push(new Header({ text: "Abattoir", value: "abattoir", divider: true, sortable: false }));
    headers.push(new Header({ text: "Reference", value: "reference", divider: true, sortable: true }));
    headers.push(new Header({ text: "Owner", value: "ownerName", divider: true, sortable: false }));
    headers.push(new Header({ text: "Qty Expected", value: "quantityExpected", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Qty Dispatched", value: "quantityDispatched", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Total Mass", value: "totalDispatchMass", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Avg Mass", value: "averageDispatchMass", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Notes", value: "notes", divider: true, sortable: false }));
    if (this.includeSettledBatches) headers.push(new Header({ text: "Settled", value: "settled", divider: true, sortable: false }));
    this.$store.commit("list/list", {
      title: "Dispatch to Abattoir Batches",
      headers: headers,
      items: [],
      typename: "DispatchToAbattoirBatch",
      onEdit: { routeName: "dispatch-to-abattoir-batch", listUponEditPath: "dispatchToAbattoirBatch" },
      onBack: { routeName: "home" },
    });
  }

  async initDispatchToSiteBatches() {
    let headers = [];
    headers.push(new Header({ text: "Type", value: "dispatchType", divider: true, sortable: false }));
    headers.push(new Header({ text: "Dispatch Date", value: "time", divider: true, sortable: false }));
    headers.push(new Header({ text: "Destination Site", value: "site", divider: true, sortable: false }));
    headers.push(new Header({ text: "Reference", value: "reference", divider: true, sortable: true }));
    headers.push(new Header({ text: "Owner", value: "ownerName", divider: true, sortable: false }));
    headers.push(new Header({ text: "Qty Expected", value: "quantityExpected", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Qty Dispatched", value: "quantityDispatched", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Total Mass", value: "totalDispatchMass", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Avg Mass", value: "averageDispatchMass", divider: true, sortable: false, align: "center" }));
    headers.push(new Header({ text: "Notes", value: "notes", divider: true, sortable: false }));
    if (this.includeSettledBatches) headers.push(new Header({ text: "Settled", value: "settled", divider: true, sortable: false }));
    this.$store.commit("list/list", {
      title: "Dispatch to Site Batches",
      headers: headers,
      items: [],
      typename: "DispatchToSiteBatch",
      onEdit: { routeName: "dispatch-to-site-batch", listUponEditPath: "dispatchToSiteBatch" },
      onBack: { routeName: "home" },
    });
  }

  async initDispensaryItems() {
    let headers = [
      // new Header({ text: "Code", value: "code" }),
      new Header({ text: "Description", value: "description" }),
      new Header({ text: "Units", value: "unit" }),
      new Header({ text: "Withdrawal", value: "withdrawal" }),
    ];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Dispensary Items",
      headers: headers,
      items: [],
      typename: "DispensaryItem",
      onEdit: { routeName: "dispensary-item", listUponEditPath: "dispensaryItem" },
      onBack: { routeName: "veterinary" },
      sortBy: "description",
      sortDesc: false,
    });
  }

  async initTreatments() {
    //let headers = [new Header({ text: "Code", value: "code" }), new Header({ text: "Description", value: "description" })];
    let headers = [new Header({ text: "Description", value: "description" })];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Treatments",
      headers: headers,
      items: [],
      typename: "Treatment",
      onEdit: { routeName: "treatment", listUponEditPath: "treatment" },
      onBack: { routeName: "veterinary" },
      sortBy: "description",
      sortDesc: false,
    });
  }

  async initVaccinations() {
    //let headers = [new Header({ text: "Code", value: "code" }), new Header({ text: "Description", value: "description" })];
    let headers = [new Header({ text: "Description", value: "description" })];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Vaccinations",
      headers: headers,
      items: [],
      typename: "Vaccination",
      onEdit: { routeName: "vaccination", listUponEditPath: "vaccination" },
      onBack: { routeName: "veterinary" },
      sortBy: "description",
      sortDesc: false,
    });
  }

  async initAilmentCategories() {
    let headers = [new Header({ text: "Category", value: "category" })];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Ailment Categories",
      headers: headers,
      items: [],
      typename: "AilmentCategory",
      onEdit: { routeName: "ailment-category", listUponEditPath: "ailmentCategory" },
      onBack: { routeName: "veterinary" },
      sortBy: "category",
      sortDesc: false,
    });
  }

  async initAilments() {
    let headers = [new Header({ text: "Ailment", value: "ailment" }), new Header({ text: "Category", value: "category" })];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Ailments",
      headers: headers,
      items: [],
      typename: "Ailment",
      onEdit: { routeName: "ailment", listUponEditPath: "ailment" },
      onBack: { routeName: "veterinary" },
      sortBy: "ailment",
      sortDesc: false,
    });
  }

  async initDispensaryItemCosts() {
    let headers = [
      // new Header({ text: "Code", value: "code" }),
      new Header({ text: "Dispensary Item", value: "description" }),
      new Header({ text: "Cost", value: "latestCostString" }),
    ];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Dispensary Item Costs",
      headers: headers,
      items: [],
      typename: "CostDispensaryItem",
      onEdit: { routeName: "dispensary-item-cost", listUponEditPath: "dispensaryItem" },
      onBack: { routeName: "costs" },
      sortBy: "description",
      sortDesc: false,
    });
  }

  async initMortalities() {
    this.$store.commit("list/list", {
      title: "Mortalities",
      headers: [
        new Header({ text: "Date", value: "humanTime", sortable: true }),
        new Header({ text: "Tag ID", value: "tagId", sortable: true }),
        new Header({ text: "Ailment", value: "ailments", sortable: false }),
        new Header({ text: "Owner", value: "owner", sortable: false }),
        new Header({ text: "Breed", value: "breed", sortable: false }),
        new Header({ text: "Gender", value: "gender", sortable: false }),
      ],
      items: [],
      typename: "Mortality",
      onEdit: { routeName: "mortality", listUponEditPath: "mortality" },
      onBack: { routeName: "home" },
      /*
        Rather sort default by data.guid, so that mortalities without a 
        selected date but are more recently made will then be at the top of 
        the list.
        
        sortBy: "humanTime"
        sortDesc: true,
      */
    });
  }

  async initOfficeHospitalResults() {
    this.$store.commit("list/list", {
      title: "Office Hospital",
      headers: [
        new Header({ text: "Date", value: "humanTime", sortable: true }),
        new Header({ text: "Tag ID", value: "tagId", sortable: true }),
        new Header({ text: "Ailment", value: "ailments", sortable: false }),
        new Header({ text: "Owner", value: "owner", sortable: false }),
        new Header({ text: "Breed", value: "breed", sortable: false }),
        new Header({ text: "Gender", value: "gender", sortable: false }),
      ],
      items: [],
      typename: "OfficeHospitalResult",
      onEdit: { routeName: "hospital", listUponEditPath: "officeHospitalResult" },
      onBack: { routeName: "veterinary" },
      sortBy: "humanTime",
      sortDesc: true,
    });
  }

  async initLostTags() {
    this.$store.commit("list/list", {
      title: "Lost Tags",
      headers: [
        new Header({ text: "Notes", value: "notes" }),
        new Header({ text: "Reference Type", value: "refTypeUi" }),
        new Header({ text: "Reference", value: "reference" }),
        new Header({ text: "Time", value: "timeUi" }),
        new Header({ text: "Tags", value: "tagsUi" }),
      ],
      items: [],
      typename: "LostTags",
      onEdit: { routeName: "lost-tags", listUponEditPath: "lostTags" },
      onBack: { routeName: "home" },
      sortBy: "time",
      sortDesc: true,
    });
  }

  async initKraals() {
    const headers = [
      new Header({ text: "Kraal ID", value: "kraalId" }),
      new Header({ text: "Type", value: "kraalType" }),
      new Header({ text: "Recommended Capacity", value: "recommendedCapacity" }),
      //new Header({ text: "Description", value: "description" }),
    ];
    if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    this.$store.commit("list/list", {
      title: "Kraals",
      headers,
      items: [],
      typename: "Kraal",
      onEdit: { routeName: "kraal", listUponEditPath: "kraal" },
      onBack: { routeName: "general" },
      sortBy: "humanTime",
      sortDesc: true,
    });

    // let items = await listKraal(!this.includeInactive);
    // items = items.map((i: any) => {
    //   //active wasn't set before, so kraals with no active set should have it set to true
    //   if (i.active !== false) i.active = true;
    //   return i;
    // });
    // this.$store.commit("list/items", items);
  }

  async rationCosts() {
    throw Error("fix List for this");
    // let costRations = (await this.$store.dispatch("data/get", { objectStore: "CostRation" })) as Models.CostRation[];
    // let items = ((await this.$store.dispatch("data/get", { objectStore: "Ration" })) as Models.Ration[]).map(ration => {
    //   //get latest cost
    //   let costRation: Models.CostRation | undefined = lodash.find(costRations, cost => cost.guid === ration.guid);
    //   let latestCostString = "N/A";
    //   if (costRation) {
    //     let latestCost: Models.CostHistoryRation | undefined = lodash.maxBy(costRation.history, historyItem => historyItem.since);
    //     if (latestCost) {
    //       if (latestCost.auto) {
    //         latestCostString = `Auto since ${this.moment(latestCost.since).format("YYYY-MM-DD")}`;
    //       } else {
    //         latestCostString = `R ${latestCost.value} per ${latestCost.per} ${latestCost.unit === "each" ? "" : latestCost.unit} since ${this.moment(
    //           latestCost.since
    //         ).format("YYYY-MM-DD")}`;
    //       }
    //     }
    //   }
    //   return { ...ration, latestCostString: latestCostString };
    // });
    // if (!this.includeInactive) items = items.filter(b => b.active);
    // let headers = [
    //   new Header({ text: "Name", value: "name" }),
    //   new Header({ text: "Description", value: "description" }),
    //   new Header({ text: "Cost", value: "latestCostString" })
    // ];
    // if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    // this.$store.commit("list/list", {
    //   title: "Ration Costs",
    //   headers: headers,
    //   items: [],
    //   typename: "Ration", //remember that here typename should be "Ration"
    //   onEdit: { routeName: "ration-cost", listUponEditPath: "ration" },
    //   onBack: { routeName: "costs" }
    // });
  }

  async materialCosts() {
    throw Error("fix List for this");
    // let costMaterials = (await this.$store.dispatch("data/get", { objectStore: "CostMaterial" })) as Models.CostMaterial[];
    // let items = ((await this.$store.dispatch("data/get", { objectStore: "Material" })) as Models.Material[]).map(material => {
    //   //get latest cost
    //   let costMaterial: Models.CostMaterial | undefined = lodash.find(costMaterials, cost => cost.guid === material.guid);
    //   let latestCostString = "N/A";
    //   if (costMaterial) {
    //     let latestCost: Models.CostHistory | undefined = lodash.maxBy(costMaterial.history, historyItem => historyItem.since);
    //     if (latestCost) {
    //       latestCostString = `R ${latestCost.value} per ${latestCost.per} ${latestCost.unit === "each" ? "" : latestCost.unit} since ${this.moment(
    //         latestCost.since
    //       ).format("YYYY-MM-DD")}`;
    //     }
    //   }
    //   return { ...material, latestCostString: latestCostString };
    // });
    // if (!this.includeInactive) items = items.filter(b => b.active);
    // let headers = [
    //   new Header({ text: "Name", value: "name" }),
    //   new Header({ text: "Description", value: "description" }),
    //   new Header({ text: "Cost", value: "latestCostString" })
    // ];
    // if (this.includeInactive) headers.push(new Header({ text: "Active", value: "active" }));
    // this.$store.commit("list/list", {
    //   title: "Material Costs",
    //   headers: headers,
    //   items: [],
    //   typename: "Material", //remember that here typename should be "Material"
    //   onEdit: { routeName: "material-cost", listUponEditPath: "material" },
    //   onBack: { routeName: "costs" }
    // });
  }
}
